// This way we can pre define the valid keys for gradient, and we will get hints for those when we use this type
import { ThemeOptions } from '@material-ui/core/styles/createTheme';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
// eslint-disable-next-line import/no-unresolved
import { Overrides } from '@material-ui/core/styles/overrides';

import { mergeDeep } from '../services/utils/Object';

const validGradientKeys = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100] as const;
type ValidGradientKeys = (typeof validGradientKeys)[number];

// This way we can pre define the valid keys for colors, and we will get hints for those when we use this type
const validColors = ['blue', 'sherry', 'green', 'yellow', 'gray', 'error'] as const;
type ValidColors = (typeof validColors)[number];

type ColorGradientDictionary = {
  readonly [key in ValidGradientKeys]: string;
};

type ColorPalette = {
  readonly [key in ValidColors]: ColorGradientDictionary;
};

export const colorPalette: ColorPalette = {
  blue: {
    10: '#EDECFD',
    20: '#E4E3FD',
    30: '#D1D0FB',
    40: '#A2A0F8',
    50: '#7D7AF5',
    60: '#534FF2',
    70: '#1712ED',
    80: '#120EBE',
    90: '#0E0B8E',
    100: '#07064C',
  },
  sherry: {
    10: '#FFF5EB',
    20: '#FFE9D2',
    30: '#FECCB3',
    40: '#FEAB90',
    50: '#FE8067',
    60: '#FD5444',
    70: '#E32D1C',
    80: '#B62416',
    90: '#881B11',
    100: '#440E08',
  },
  green: {
    10: '#E8FDF0',
    20: '#B9F9D1',
    30: '#8AF5B2',
    40: '#5BF193',
    50: '#2CEC74',
    60: '#13D35B',
    70: '#0EA447',
    80: '#0A7533',
    90: '#06461E',
    100: '#042F14',
  },
  yellow: {
    10: '#FFF8E5',
    20: '#FFEAB3',
    30: '#FFDB80',
    40: '#FFCD4D',
    50: '#E6A500',
    60: '#E6A500',
    70: '#B38000',
    80: '#805C00',
    90: '#4D3700',
    100: '#332300',
  },
  gray: {
    10: '#F9F7F5',
    20: '#F4F0EC',
    30: '#E8E5E3',
    40: '#C0BBBA',
    50: '#A7A09F',
    60: '#787473',
    70: '#655F5D',
    80: '#544845',
    90: '#41332F',
    100: '#34221E',
  },
  error: {
    10: '#FCE8F2',
    20: '#F7BAD8',
    30: '#F28CBE',
    40: '#ED5EA3',
    50: '#E83189',
    60: '#CE1770',
    70: '#A11257',
    80: '#730D3E',
    90: '#450825',
    100: '#2D0618',
  },
};

export const getGradientColor = (color: ValidColors, gradient: ValidGradientKeys): string => {
  if (!colorPalette[color]) {
    throw new Error('requested color is not part of the palette.');
  }

  if (!colorPalette[color][gradient]) {
    throw new Error('requested color is not part of the palette, check gradiant value.');
  }

  return colorPalette[color][gradient];
};

// https://github.com/mui-org/material-ui/blob/v0.x/src/styles/getMuiTheme.js
// Mayple color palette:
export const colors = {
  primary: getGradientColor('blue', 60),
  secondary: getGradientColor('sherry', 60),
  black: '#241815',
  black_light: getGradientColor('gray', 80),
  black_lighter: getGradientColor('gray', 60),
  black_lightest: getGradientColor('gray', 40),
  blackOpacity: (a: number): string => `rgba(36, 24, 21, ${a})`, // creates a rgba for black color.
  white: '#FFFFFF',
  white_dark: getGradientColor('gray', 30),
  white_darker: getGradientColor('gray', 20),
  white_darkest: getGradientColor('gray', 10),
  // creates a rgba for white color.
  whiteOpacity: (a: number): string => `rgba(255, 255, 255, ${a})`,
  cherry: getGradientColor('sherry', 60),
  cherry_darker: getGradientColor('sherry', 80),
  cherry_lighter: getGradientColor('sherry', 20),
  blue_lightest: getGradientColor('blue', 10),
  blue_lighter: getGradientColor('blue', 30),
  blue: getGradientColor('blue', 60),
  blue_dark: getGradientColor('blue', 70),
  blue_darker: getGradientColor('blue', 80),
  blue_darkest: getGradientColor('blue', 90),
  green_lighter: getGradientColor('green', 10),
  green_light: getGradientColor('green', 20),
  green: getGradientColor('green', 30),
  green_dark: getGradientColor('green', 40),
  green_darker: getGradientColor('green', 70),
  green_darkest: getGradientColor('green', 80),
  teal_light: getGradientColor('green', 20),
  teal: getGradientColor('green', 50),
  teal_dark: getGradientColor('green', 40),
  yellow_lighter: getGradientColor('yellow', 10),
  yellow_light: getGradientColor('yellow', 20),
  yellow: getGradientColor('yellow', 40),
  yellow_dark: getGradientColor('yellow', 60),
  yellow_darker: getGradientColor('yellow', 70),
  error: getGradientColor('error', 10),
  red: getGradientColor('error', 70),
  error_text: getGradientColor('error', 70),
  red_light: getGradientColor('error', 40),
  red_lighter: getGradientColor('error', 10),
  gray: getGradientColor('gray', 30), // '#DADADA',
  site_background_main: 'linear-gradient(180deg, #E8E2E2 0%, #DCDCE5 30.73%, #E5D8D8 63.54%, #D3D9E5 100%)',
  site_background_light: 'linear-gradient(180deg, #F5F5F5 0%, #F8F4F2 100%)',
  specialBackground: 'linear-gradient(98.42deg, #F1F1FE 0%, #FFF5EB 100%)',
  borderLight: getGradientColor('gray', 30),
};

const typography: TypographyOptions = {
  fontFamily: ['"GT Walsheim Pro"', 'Roboto', 'sans-serif'].join(','),
  h1: {
    fontSize: '3.5rem',
    lineHeight: '5rem',
  },
  h2: {
    fontSize: '3rem',
    lineHeight: '4rem',
  },
  h3: {
    fontSize: '2.5rem',
    lineHeight: '3.5rem',
  },
  h4: {
    fontSize: '2rem',
    lineHeight: '2.5rem',
  },
  h5: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
  },
  h6: {
    fontSize: '1.25rem',
    fontWeight: 400,
    lineHeight: '1.625rem',
  },
  subtitle1: {
    fontSize: '1rem',
    lineHeight: '1.75rem',
  },
  subtitle2: {
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
  },
  body1: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  body2: {
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
  },
  caption: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
  },
  overline: {
    fontSize: '0.75rem',
    lineHeight: '2rem',
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  button: {
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    fontWeight: 'bold',
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
  },
};

// overrides the default theme of each MUI version 4.x component
const overrides: Overrides = {
  MuiCssBaseline: {
    '@global': {
      html: {
        scrollbarWidth: 'thin' /* FF definition to scroll bar width */,
        fontFamily: `"Adelle-sans", Roboto, sans-serif`,
        fontSize: 16,
        '& ::-webkit-scrollbar-track': {
          borderRadius: 10,
          backgroundColor: '#F5F5F5',
        },
        '& ::-webkit-scrollbar': {
          width: 8,
          height: 8,
          backgroundColor: '#F5F5F5',
        },
        '& ::-webkit-scrollbar-thumb': {
          borderRadius: 6,
          backgroundColor: '#818293',
        },
      },

      img: {
        maxWidth: '100%',
        maxHeight: '100%',
      },

      a: {
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
  MuiButton: {
    root: {
      borderRadius: 50,
      fontWeight: 'bold',
    },
  },
  MuiPaper: {
    rounded: {
      borderRadius: 16,
    },
  },
  MuiCheckbox: {
    root: {
      color: colors.black_lighter,
    },
    colorSecondary: {
      '&$checked': {
        color: colors.blue,
      },
    },
  },
  MuiFormLabel: {
    root: {
      color: colors.black_lighter,
    },
  },
  MuiStepLabel: {
    label: {
      color: colors.black,
    },
  },
  MuiBreadcrumbs: {
    ol: {
      alignItems: 'baseline',
    },
  },
  MuiTooltip: {
    tooltip: {
      fontSize: 14,
    },
  },
  MuiDivider: {
    root: {
      marginTop: 16,
      marginBottom: 16,
    },
  },
  MuiAvatar: {
    root: {
      marginRight: 8,
    },
  },
  MuiTabs: {
    indicator: {
      backgroundColor: colors.black,
    },
  },
  MuiTab: {
    root: {
      fontWeight: 'normal',
      boxShadow: 'inset 0px -1px 0px #F5F2EE',
    },
  },
  MuiChip: {
    root: { margin: 4, backgroundColor: colorPalette.gray[20] },
  },
  MuiRadio: {
    checked: {},
  },
};

export const theme: ThemeOptions = {
  palette: {
    type: 'light',
    primary: {
      // primary color of the MUI theme
      main: colors.primary,
    },
    secondary: {
      // secondary color of the MUI theme
      main: colors.secondary,
    },
    action: {},
    text: {
      // set the theme text colors
      primary: colors.black,
      secondary: colorPalette.gray[60],
      disabled: colors.black_light,
    },
  },
  typography,
  overrides: mergeDeep({}, overrides, {
    MuiCssBaseline: {
      '@global': {
        a: {
          color: colors.black_light,
        },
      },
    },

    MuiButtonBase: {
      root: {
        color: colors.black,
        textDecoration: 'none',
      },
    },
  }),
};

export const darkTheme: ThemeOptions = {
  palette: {
    type: 'dark',
    primary: {
      // primary color of the MUI theme
      main: colors.white_dark,
    },
    secondary: {
      // secondary color of the MUI theme
      main: colors.black,
    },
    action: {
      active: colors.white,
    },
  },
  typography,
  overrides: mergeDeep({}, overrides, {
    MuiCssBaseline: {
      '@global': {
        a: {
          color: colors.white_dark,
        },
      },
    },

    MuiButtonBase: {
      root: {
        color: colors.white_dark,
        textDecoration: 'none',
      },
    },

    MuiChip: {
      root: {
        backgroundColor: colorPalette.gray[70],
      },
    },

    MuiRadio: {
      colorPrimary: {
        '&.Mui-checked': {
          color: colors.primary,
        },
      },
      colorSecondary: {
        '&.Mui-checked': {
          color: colors.secondary,
        },
      },
    },
  }),
};

// console.log('theme', theme);
// console.log('darkTheme', darkTheme);

// Freezing the objects will prevent them from being changed
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze
Object.freeze(colors);
Object.freeze(theme);
Object.freeze(darkTheme);
