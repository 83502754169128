import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { IdentityDetails, User, UserType } from '@mayple/types';
import { useHistory } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import * as Sentry from '@sentry/react';
import { Link, useParams } from 'react-router-dom';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { createMaypleAnalyticsGlobalObject } from '@mayple/analytics';
import { ViewerUserQuery } from 'growl-graphql/dist/queries/ViewerUserQuery';
import { IdentityDetailsQuery } from 'growl-graphql/dist/queries/IdentityDetailsQuery';

import links from './consts';
import { config } from '../../../config';
import { getCrackleUrl } from '../../../app/utils';
import GlobalEntitySearch from '../GlobalEntitySearch';
import { SearchResultsPageParams } from '../../../pages/SearchResultsPage';

import { logos } from '../../../../fe_common/client/app/logos';
import { RefreshSpinner } from '../../../../fe_common/client/components/atoms';
import { Container } from '../../../../fe_common/client/components/utility/Container';
import { identifyUser } from '../../../../fe_common/client/logic/events';
import { setNotification } from '../../../../fe_common/client/services/notification';
import { useDeviceInfo } from '../../../../fe_common/client/hooks';
import QueryErrorMessage from '../../../../fe_common/client/components/common/QueryErrorMessage';
import SwitchField from '../../../../fe_common/client/components/atoms/SwitchField';

import useStyles from './style';

type DashboardProps = {
  isDarkMode: boolean;
  setIsDarkMode: Dispatch<SetStateAction<boolean>>;
};

const Dashboard: FC<DashboardProps> = (props) => {
  const classes = useStyles(props);
  const { isDarkMode, setIsDarkMode } = props;
  const history = useHistory();
  const { isMobile } = useDeviceInfo();

  const [initialized, setInitialized] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);
  const { searchTerm: searchTermFromUrl } = useParams<SearchResultsPageParams>();

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const { loading: loadingViewer, error: errorViewer, data: dataViewer } = useQuery(ViewerUserQuery.query);
  const {
    loading: loadingIdentityDetails,
    error: errorIdentityDetails,
    data: dataIdentityDetails,
  } = useQuery(IdentityDetailsQuery.query);

  const isLoading = loadingIdentityDetails || loadingViewer;

  const identityDetails = useMemo(
    () => dataIdentityDetails?.identityDetails || ({} as IdentityDetails),
    [dataIdentityDetails],
  );
  const viewer = useMemo(() => dataViewer?.viewer || ({} as User), [dataViewer]);

  const identifyUserInSentry = useCallback(() => {
    const { emailAddress, id, displayName } = viewer;

    if (!emailAddress) {
      return;
    }

    Sentry.getCurrentScope().setUser({
      id,
      email: emailAddress,
      username: displayName,
    });
  }, [viewer]);

  const onMaypleAnalyticsLoadSuccess = useCallback(() => {
    setInitialized(true);
    // First call to identify right after analytics is loaded to prevent anonymous sessions calls
    identifyUser(viewer);
    identifyUserInSentry();
    // reportGroupEvent(viewer.maypleUniversalTrackingId, identityDetails.internalTeam);
  }, [viewer, identifyUserInSentry]);

  const onMaypleAnalyticsLoadFail = useCallback(() => {
    setNotification(
      'Dear Maypler, Cpanel could not load analytics tracking. You can still use cpanel as normal,' +
        ' but should report to dev team. ' +
        'In the meanwhile try whitelisting this site in your ad-blocker if you have one.',
      'warning',
    );
  }, []);

  const createGlobalObjectForMaypleAnalytics = useCallback(() => {
    const { segmentClientId } = config;

    const { emailAddress = '', maypleUniversalTrackingId } = viewer;

    if (!emailAddress || !maypleUniversalTrackingId) {
      return;
    }

    createMaypleAnalyticsGlobalObject(
      {
        segmentClientId,
        debug: false,
        userId: maypleUniversalTrackingId,
      },
      onMaypleAnalyticsLoadSuccess,
      onMaypleAnalyticsLoadFail,
    );
  }, [viewer, onMaypleAnalyticsLoadSuccess, onMaypleAnalyticsLoadFail]);

  useEffect(() => {
    if (!isLoading && !initialized) {
      createGlobalObjectForMaypleAnalytics();
      setDrawerOpen(!isMobile);
    }
  }, [isLoading, initialized, createGlobalObjectForMaypleAnalytics, isMobile]);

  // TODO: Add group report after we refactor this to FC
  // const reportGroupEvent = (maypleUniversalTrackingId: string, internalTeam: InternalTeam) => {
  //   window.mayple_analytics.identify(maypleUniversalTrackingId, { persona: AccountType.INTERNAL_TEAM });
  //   window.mayple_analytics.groupFromInternalTeam(internalTeam);
  // };

  const onSearchHandler = (searchTerm: string) => {
    history.push(`/search-results/${searchTerm}`);
  };

  const onChangeDarkModeHandler = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
    setIsDarkMode(checked);

  const renderLink = (title: string, link: string) => (
    <React.Fragment key={title.replace(/\s/g, '-')}>
      <Link to={link}>
        <MenuItem>{title}</MenuItem>
      </Link>
    </React.Fragment>
  );

  if (isLoading) {
    return <RefreshSpinner />;
  }

  if (!identityDetails) {
    const crackleUrl = getCrackleUrl();

    return (
      <Grid container>
        <Grid item xs={12}>
          <h1>
            please{' '}
            <a href={crackleUrl} rel="noopener noreferrer" target="_blank">
              login through app
            </a>{' '}
            then refresh this page
          </h1>
        </Grid>
      </Grid>
    );
  }

  const userType = identityDetails?.userType || UserType.NORMAL_USER;
  const isSupportOrAdminUser = [UserType.ADMIN_USER, UserType.SUPPORT_USER].includes(userType);

  if (!isSupportOrAdminUser) {
    const userEmail = viewer?.emailAddress || 'N/A';

    return (
      <Grid container>
        <Grid item xs={12}>
          <h1>Sorry, you are not allowed here</h1>
          <h4>
            Restricted access to
            <a href="https://www.mayple.com" rel="noopener noreferrer" target="_blank">
              {' '}
              Mayple
            </a>{' '}
            admin/support users
          </h4>
          <p>(Currently logged in with email: {userEmail})</p>
        </Grid>
      </Grid>
    );
  }

  // move this to outside file?
  const drawerChildren = (
    <div>
      <div style={{ textAlign: 'center' }}>
        <Link className="brand-logo" to="/">
          <img style={{ maxHeight: 64, margin: 10 }} src={isDarkMode ? logos.logoLight : logos.logoDark} alt="Mayple" />
        </Link>
      </div>
      {links.map(({ label, href }) => renderLink(label, href))}
    </div>
  );

  return (
    <div
      className={classNames(classes.root, {
        'dark-mode': isDarkMode,
      })}
    >
      <AppBar
        color={isDarkMode ? 'secondary' : 'primary'}
        position="static"
        className={classNames(classes.appBarContent, { [classes.drawerOpen]: drawerOpen })}
      >
        <Toolbar className={classNames(classes.toolbarRoot, { [classes.toolbarRootDrawerOpen]: drawerOpen })}>
          <IconButton color="inherit" onClick={handleDrawerOpen} aria-label="Menu">
            <MenuIcon />
          </IconButton>
          <SwitchField
            color={isDarkMode ? 'primary' : 'secondary'}
            label={isDarkMode ? 'Dark' : 'Light'}
            checked={isDarkMode}
            onChange={onChangeDarkModeHandler}
          />
          <div className={classes.grow} />
          <GlobalEntitySearch onSearch={onSearchHandler} initialSearchValue={searchTermFromUrl} />
        </Toolbar>
      </AppBar>
      <Drawer
        open={drawerOpen}
        variant={isMobile ? 'temporary' : 'persistent'}
        anchor="left"
        classes={{ paper: classes.drawerPaper }}
      >
        <div className={classes.closeMenuButtonWrapper}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        {drawerChildren}
      </Drawer>
      <main className={classNames(classes.mainContent, { [classes.drawerOpen]: drawerOpen })}>
        <QueryErrorMessage
          error={errorViewer || errorIdentityDetails}
          message="Some error occurred while getting user data... Please try and refresh the page."
        />
        <Container>{props.children}</Container>
      </main>
    </div>
  );
};

export default Dashboard;
